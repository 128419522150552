<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <g-card page-title="Failed Queued Logs" page-icon="mdi-comment-alert">
    <g-list-page
        :headers="headers"
        :actions="actions"
        stateendpoint="failedQueuedLogs.failedQueuedLogs"
        sort="queue"
        @loadData="loadData"
    >
      <template v-slot:overlay>
        <page-overlay :overlay="isOverlay" />
      </template>
      <template v-slot:top>
        <v-row>
          <v-col cols="12" md="6" sm="8">
            <g-p-search
                hint="Minimum two characters needed to search. Columns(queue name)"
                @applySearch="handleSearch"
            />
          </v-col>
          <v-col cols="12" md="6" sm="4">
            <add-button
                text="Remove all logs"
                icon="mdi-bookmark-remove"
                :toolbar="true"
                @onButtonClick="removedLogs({}, false)"
                class="ma-2 justify-end"
            />
          </v-col>
        </v-row>
      </template>
    </g-list-page>
    <confirm-popup
        :show="showConfirm"
        confirm-event="onConfirmItem"
        @closePopupHandler="showConfirm = false"
        @onConfirmItem="confirmEvent(currentSelectedItem, true)"
    >
      <div class="pt-5 pb-3">
        <!-- eslint-disable-next-line  -->
        <span>{{ confirmTxt }}</span>
      </div>
    </confirm-popup>
  </g-card>
</template>
<script>
import ListMixins from "../../components/mixins/list/List";
import GPSearch from "../../components/GPSearch";
import PageOverlay from "../../components/PageOverlay";
import GListPage from "../../components/list/GListPage";
import GCard from "../../components/GCard";
import ConfirmPopup from "../../components/popups/ConfirmPopup";
import AddButton from "../../components/buttons/AddButton";

export default {
  name: 'FailedQueuedLogs',
  metaInfo: {
    title: 'rabbiitfirm.com',
    titleTemplate: 'Admin Dashboard - Failed Queued Logs | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {
    AddButton,
    ConfirmPopup,
    GCard,
    GListPage,
    PageOverlay,
    GPSearch,
  },
  mixins: [ListMixins],
  data() {
    return {
      headers: [
        {
          text: 'Queue Name',
          value: 'queue'
        },
        {
          text: 'Total Queued',
          align: 'right',
          value: 'totalQueued'
        }
      ],
      actions: {
        load: 'failedQueuedLogs/load',
        clear: 'failedQueuedLogs/clear'
      }
    }
  }
}
</script>
